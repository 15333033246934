.spaceFloorplan{
    position:absolute;
    z-index: 23;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 30px;
    
    
}

.marker-cluster-small {
    background-color: rgba(41,129, 202, 0.6)  !important;
}
  
.marker-cluster-small div {
    background-color: rgba(41,129, 202, 0.6) !important;
    color: #fff !important;
}

.marker-cluster-medium {
    background-color: rgba(41,129, 202, 0.6)  !important;
}

.marker-cluster-medium div {
    background-color: rgba(41,129, 202, 0.6) !important;
    color: #fff !important;
}

.marker-cluster-large {
    background-color: rgba(41,129, 202, 0.6)  !important;
}

.marker-cluster-large div {
    background-color: rgba(41,129, 202, 0.6) !important;
    color: #fff !important;
}



.spaceFloorplan-bounds{
    z-index: 100;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding:25px;
    background-color: white;

}

.spaceFloorplan-leaflet{
    padding-top: 25px;
    width: 100%;
    height: calc(100% - 50px);
}

.spaceFloorplan-title{
    height: 50px;
    border: 1px;
    border-style: solid !important;
    border-color: #E0E0E0;
    border-top: 0ch;
    border-left: 0px;
    border-right: 0px;
    font-size: 20px;
    font-weight:500;
    padding-top: 1px;
    display: flex;
    justify-content: space-between;
}

.spaceFloorplan-close{

    height: 25px;
    width: 25px;
    border-radius: 100px;

}

.spaceFloorplan-close:hover{
    cursor: pointer;
    background-color: lightgray;
    
}