/* .dateRange{
    margin-top: 50px;
} */
.marginZeroEvents{
    margin-top: 0px;
    margin-bottom: 0px;
}
.eventscontainer{
    display: block;
    background: #fff;
}
.datePopup{
    margin-top: 30px;
}
.dateBtnEvent{
    position: absolute;
    top: 100px;
    left: 17px;
    z-index: 99;
    display: flex;
    color: #757575;
}
.pageFullWidthEvents{
    padding: 10px;
    top:5px
}
p.eventsDesc{
    margin-top: 5px;
}
.analyticsContainerEvents {
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-direction: row;
    margin-top: 10px;
}
#events {
    margin-top: 25px;
    margin-left: 10px;
    width:99%;
}
.content.eventscontainer {
    background: #fff !important;
}
.eventAnalyticsPage{
    width: 100%;
    margin-top: 20px;
}
.events-clearDate{
    padding: 10px 10px;
    color: #d71109;
    cursor: pointer !important;
    white-space: nowrap;
}
#eventChart{
	height: 180px;
	/* width: 80; */
	cursor: pointer;
    overflow: hidden;
    max-width: 100%;
}
.Events_text_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}
.Events_text_wrapper .uploadBtnAssetsReport{
    position: unset;
}
.Events_text_wrapper #generatePdfButton{
    margin-bottom: 0;
}
.btnDateContainerEvents{
    display: flex;
    /* padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 0px;
    padding-top: 25px; */
    align-items: start;
}
.dx-datagrid-filter-panel-checkbox {
    display: none; /* Hide the checkbox */
}
.dx-datagrid-export-button .dx-button-content{
    content:url(../../../public/icons/excel.svg);
    height: 36px;
    width: 36px;
    padding: 9px !important;
}
.pageFullWidthAnalyze {
    min-height: calc(100vh - 50px);
}

.download_btn{
    display: flex;
    align-items: center;
    justify-items: center;
}
.download_btn img{
    width: 22px;
    height: 22px;
}
.download_btn:hover{
    background: rgba(0,0,0,0.08);
}