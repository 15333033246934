/* div#mechanicalChart {
    width: 100% !important;
    height: 200px;
} */
.dateBtnMechanical{
    display: flex;
    margin-right: 20px;
    flex-flow: row;
    padding-bottom: 10px;
}
.mechanicalContainer{
    height: auto;
    background-color: white;
    overflow: hidden;
}
.side-content-mechanical {
    flex: 0 0 auto;
    margin-right: 20px;
}
.side-content-mechanical {
    /* float: left; */
    /* width: 200px; */
    margin-right: 0px;
    margin-left: 25px;
    margin-top: 200px;
  }
.mechanical-container {
    display: flex;
    align-items: flex-start; /* Align items to the top */
    margin-top: 40px;
    text-align: center;
}

.uploadBtnMechanicalReport{
    top: 8px;
    right: 200px;
    padding: 6px;
    border-radius: 30px;
    line-height: normal;
    padding-left: 10px;
}
