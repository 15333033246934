.legendFloorPlan{
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 10px;
    display: flex;
}

.legendFloorPlanMultiple{
    position: absolute;
    bottom: 0px;
    left: 10px;
    padding: 10px;
    /* display: flex; */
    z-index: 9999;
}

.legendNone{
    display: none;
}

.legendText{
    color: black;
    margin-right: 10px;
    text-align: right;
    padding-top: 2px;
    z-index: 1000;
}

.legendTextBeside{
    color: black;
    z-index: 1000;
}

.circle{
    width: 30px !important;
    height: 30px !important;
}

.floorplan-5mGrid{
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 5px;
    padding: 5px;
    /* border-style: solid;
    border-width: 2px;
    border-radius: 3px; */
    /* background-color: white;
    border-color: #B1B1B1; */
    display: flex;
    z-index: 1000;
}

.floorplan-5mGrid:hover{
    cursor: pointer;
    /* border: 2px solid #000;
    font-weight: normal; */
}

.floorplan-toggle{
    position: absolute;
    top: 80px;
    right: 10px;
    margin: 5px;
    padding: 5px;
    display: flex;
    z-index: 1000;
}

.floorplan-1mGrid{
    z-index: 1000;
    position: absolute;
    top: 45px;
    right: 10px;
    margin: 5px;
    padding: 5px;
    /* border-style: solid; */
    /* border-width: 2px; */
    /* border-radius: 3px; */
    /* background-color: white;
    border-color: #B1B1B1; */
    display: flex;
}

.floorplan-1mGrid:hover{
    cursor: pointer;
    /* border: 2px solid #000; */
    font-weight: normal;
}

/* Legend positioned at bottom-left */

.legendItem {
    display: flex !important;
    align-items: center;
  }
  
  .legendColorBox {
    width: 10px;
    height: 10px;
    border-radius: 50%; /* Makes it a circle */
    margin-right: 8px;
  }
  
  .legendEllipsis {
    font-size: 14px;
    color: gray;
  }
  
  /* Tooltip for full legend */
    .legendTooltip {
        position: absolute;
        left: 0;
        background: rgba(255, 255, 255, 0.95);
        border: 1px solid #ccc;
        padding: 8px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        z-index: 1100;
        white-space: normal;
        max-width: 180px; 
        min-width: 150px;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        flex-direction: column;
        /* Dynamically adjust position */
        bottom: auto;
        top: 100%; /* Default: Position below legend */
        transform: translateY(10px); /* Add small gap */
    }

    /*  Move tooltip above legend only if there's space */
    @media (min-height: 400px) {
        .legendTooltip {
            top: auto;
            bottom: 100%; /* Move above legend */
            transform: translateY(-10px);
        }
    }
    /* Ensure tooltip appears on hover */
    .legendFloorPlanMultiple:hover .legendTooltip {
        display: block;
        visibility: visible;
    }
