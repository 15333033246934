.overviewcontainer {
	display: block;
	background-color: #fff;
}

.pageFullWidth{
	width: 100%;
	padding: 10px 25px;
}

.pageFullWidthAnchour{
	width: 100%;
	padding: 10px;
}

.analyticsContainer {
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-direction: row;
}

.analytics {
	width: 50%;
}

.analyticsAnchorsGateways {
	width: 50%;
	/* padding: 10px 2px; */
}

.dxc-hole-template {
	outline: none !important;
}

.marginZero{
	margin: 0;
}

.eventAnalytics {
	width: 100%;
}

#overviewChart{
	height: 180px;
	/* max-width: 95%; */
	/* width: 80; */
	cursor: pointer;
	/* overflow: hidden; */
}
 #event-chart-series{
	cursor: pointer;
 }
 button#generatePdfButton,button#columnChooserBtn {
    float: right;
    margin-top: 0px;
    /* margin-bottom: 29px; */
    border: none;
	cursor: pointer !important;
	background: transparent;
	padding: 0;
	height: 24px;
}