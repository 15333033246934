.networkTool-spaceDropdown{
    height: 50px;
    display:flex;
    padding: 5px;
    padding-left: 15px;
    /* color: white; */
    background-color: white;
}

.networkTool-chart{
    width: 100%;
}

.uploadBtntags{
    padding-left: 0px;
    padding-top: 10px;
}

input[type=textDevX] {
    /* width: 100%; */
    max-width: 800px;
    width: 300px;
    height: 40px;
    /* padding: 12px 20px; */
    /* margin: 8px 0; */
    padding-left: 20px;
    margin-right: 10px;
    display: inline;
    border: 1px solid darkgray;
    border-radius: 4px;
    box-sizing: border-box;
}

form[type=formDevX] {
    /* width: 100%; */

    display: flex;
    flex-flow: row;

}

.form-label{
    margin: 10px;
}

.networkTool-renderDetail{
    background-color: rgb(167, 9, 9);
    padding: 2px;
}

.networkTool-tab{
    position: fixed;
    top: 105px;
    margin-left: -15px;
}

.networkTool-datetime{
    display: flex;
    flex-flow: row;
}

.networkTool-datetimeBtn{
    margin-left: 20px;
}

.dateBtn3{
    height:50px;
    display: block;
    position: relative;
    top: 5px;
    left: 15px;
    background-color: white;
}

.dateBtn3-none{
    visibility: hidden;
    height: 0px !important;
    
}

.display-none{
    display: none;
    visibility: hidden;
}

.networkTool-visible{
    visibility:visible;
    height: 800px !important;
}

.networkTool-toggle{
    margin-top: 15px;
    margin-left: 30px;
    display: flex;
    flex-flow: row;
}

.networkTool-container{
    overflow:scroll;
    overflow-x:hidden;
    /* height: calc(100vh - 52px - 48.3px); */
    height: calc(100vh - 100px);
    min-width: calc(100vw - 250px);
    background-color: white;
}

.networkTool-export{
    z-index: 999;
    position: relative;
    top:-390px;
    left:15px;
    padding:5px;
    padding-left: 13px;
    border: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: grey;
    width: 70px;
    margin-bottom: -30px;
}

.networkTool-export2{
    z-index: 999;
    position: relative;
    top:-390px;
    left:15px;
    padding:5px;
    padding-left: 13px;
    border: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: grey;
    width: 70px;
    margin-bottom: -30px;
}

.networkTool-export3{
    z-index: 999;
    position: relative;
    top:-390px;
    left:15px;
    padding:5px;
    padding-left: 13px;
    padding-right: 13px;
    border: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: grey;
    margin-bottom: -30px;
    background-color: white;
    font-size: 14px;
}

.networkTool-export:hover{
    background-color: rgba(0, 0, 0, 0.096);
    font-weight:500;
}


.networkTool-btnsContainer{
    display: flex;
    flex-flow: row;
    margin-left: 15px;
}

.networkTool-btns{
    padding:5px;
    border: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: grey;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.networkTool-btnsActive{
    padding:5px;
    border: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: grey;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.096);
    font-weight:500;
}

.networkTool-btns:hover{
    background-color: rgba(0, 0, 0, 0.096);
    font-weight:500;
}

.networkTool-logo{
    width:20px;
}

.legend{
    position: absolute;
    bottom: 50px;
    left: 10px;
    padding: 10px;
    display: flex;
}

.legendNone{
    display: none;
}

.legendText{
    color: black;
    margin-right: 10px;
    text-align: right;
    padding-top: 2px;
}

.legendTextBeside{
    color: black;
}

.legendTextBesideEach{
    margin-top: 5px
}

.networkTool-legacy-switch{
    padding-left: 10px;
    padding-top: 10px;
}
.networkTool-legacy-switch-text{
    padding-left: 25px;
    padding-top: 10px;
}

.networkToolAnalyze-toggles{
    z-index: 999;
    position: relative;
    top:14px;
    left:95px;
    padding:5px;
    padding-left: 13px;
    margin-bottom: -30px;
    display: flex;
}

.networkToolAnalyze-togglesText{
    margin-left: 10px;
    margin-right: 10px;
}

/* .dateBtn2{
    position: absolute;
    top: 5px;
    left: 17px;
    z-index: 99;
    display: flex;
    color: #757575;
} */
