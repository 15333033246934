.entityGroup-add{
    position: absolute;
    right:270px;
    top: 10px;
    font-size: 24px;
    z-index: 999;
}

.entityGroup-add-rule{
    position: absolute;
    top: 10px;
    font-size: 24px;
    z-index: 999;
    width: 100%
}
.dx-texteditor-container{
    /* z-index:99; */
}

.dx-icon-add{
    cursor: pointer;
}

.entityGroup-setNote{
    color: #D71109;
    font-weight: 500;
    position: absolute;
    bottom: 40px;
    left: 20px;
    line-height: 23px;
    font-weight: 500;
    letter-spacing: .04em;
}

.entityGroup-setEntities{
    background-color: #D71109;
    color: #fff;
    font-weight: 500;
    position: absolute;
    bottom: 10px;
    right: 90px;
    line-height: 23px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 450;
    letter-spacing: 0.04em;
    border: none;
    height: 36px;
    padding: 6px 8px;
    border-radius: 5px;
}
.entityGroup-setEntitiesCancel{
    background-color: #D71109;
    color: #fff;
    font-weight: 500;
    position: absolute;
    bottom: 10px;
    right: 20px;
    line-height: 23px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 450;
    letter-spacing: 0.04em;
    border: none;
    height: 36px;
    padding: 6px 8px;
    border-radius: 5px;
}
.entityGroup-setEntitiesCancel:hover {
    background-color: #333;
    color: #fff;
    font-weight: 500;
    position: absolute;
    bottom: 10px;
    right: 20px;
    line-height: 23px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .04em;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 450;
    letter-spacing: 0.04em;
    border: none;
    height: 36px;
    padding: 6px 8px;
    border-radius: 5px;
}
.entityGroup-setEntities:hover{
    background-color: #333;
    color: #fff;
    font-weight: 500;
    position: absolute;
    bottom: 10px;
    right: 90px;
    line-height: 23px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .04em;
    cursor: pointer;
}

.entityGroup-setEntities:hover{
    cursor: pointer;
}

.testJ{
    width: 100%;
    height: 50px;
}

.entityRuleMap-email{
    padding-top: 26px;
}

.entityRuleMap-select{
    /* padding-bottom: 20px; */
    display: flex;
    flex-flow: row;
    width: 100%
}

.severity-select{
    padding-bottom: 20px;
    display: flex;
    flex-flow: row;
    width: 100%
}

.entityRuleMap-name{
    padding-bottom: 16px;
}

.entityRuleMap-space{
    /* padding-top: 26px; */
    padding-bottom: 20px;
}

.entityRuleMap-space2{
    /* padding-bottom: 10px; */
}

.entityRuleMap-spaceNone{
    /* padding-top: 26px; */
    /* visibility: hidden; */
    display: none;
}

.entityRuleMap-emailList:hover{
    cursor: pointer;
}

.entityRuleMap-emailListContainer{
    display: flex;
    flex-flow: row;
    margin-left: 35px;
    margin-top: 5px;
}

.dx-show-invalid-badge.dx-selectbox.dx-textbox.dx-texteditor.dx-dropdowneditor-button-visible.dx-editor-filled.dx-widget.dx-dropdowneditor.dx-dropdowneditor-field-clickable {
    width: 100%;
}
#entity-rule-map-grid .dx-item.dx-toolbar-item.dx-toolbar-button {
    padding-right: 15px;
}
/* #entity-group-map-grid{
    margin-top:50px
} */
#entity-group-map-grid .dx-item.dx-toolbar-item.dx-toolbar-button {
    /* padding-right: 15px; */
    margin-top: 10px
}
#entity-rule-map-grid-rule .dx-item.dx-toolbar-item.dx-toolbar-button{
    /* padding-right: 25px; */
}
i.entity-group-add.dx-icon-close{
    font-size: 24px;
    float: right;
    position: absolute;
    right: 10px !important;
    cursor: pointer;
    top: 50px;
    z-index: 9;
}

.editentities_col .dx-icon-add{
    top: 50px;
    z-index: 9;
    right: 294px;
}

.add_asset_col .dx-icon-add{
    top: 74px;
    z-index: 9;
    right: 284px;
}
.add_asset_col .dx-icon-add.download_btn{
    top: 28px;
    z-index: 9;
    right: 268px;
}

.process-state-label{
    margin-top: 30px;
}
.process-state-input {
    margin-left: 40px;
}
.process-state-input-minutes {
    margin-left: 40px;
}
.rule-name-input{
    margin-left: 120px;
}
.custom-spacing {
    margin-bottom: 20px
}
.re-transmit-input-hours {
    margin-top: 8px;
    margin-bottom: -8px;
}
.headerHeadingPage{
    position: relative;
}
.editentities_col .dx-icon-add.addBtn{
    border-radius: 50%;
    width: 36px;
    height: 36px;
    top: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}
i.entity-group-add.dx-icon-close:hover {
    background: rgba(0,0,0,.08);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}
.editentities_col .dx-icon-add.addBtn:hover,
.entity-group-add.addBtn2:hover{
    background: rgba(0,0,0,.08);
}
.entity-group-add.addBtn2{
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    top: 0;
    top: 45px;
    right: 280px;
}
#entity-rule-map-grid-rule .dx-last-data-cell{
    padding: 0;
}
.pageFullWidthEvents-rule{
    padding: 10px;
}

  #entity-group-map-grid {
    max-height: calc(100vh - 450px);
  }
  #entity-rule-map-grid-rule{
    max-height: calc(100vh - 410px);
  }
  .headerHeadingPage.fixLayout{
    min-height: calc(100vh -373px);
    max-height: calc(100vh -373px);
    overflow: hidden auto;
  }
  
  /* Media query for small screens */
  /* @media (max-width: 768px) {
    #entity-group-map-grid {
      height: calc(100vh - 525px);
    }
    #entity-rule-map-grid-rule {
        height: calc(100vh - 525px);
    }
  } */
  
  /* Media query for large screens */
  @media all and (max-width:1024px){
    #entity-group-map-grid {
      max-height: calc(100vh - 525px);
      /* display: grid; */
      /* grid-template-columns: calc(50% - 12px) calc(50% - 12px); */
      /* gap: 20px;
      padding: 0 20px 20px; */
    }
    #entity-rule-map-grid-rule {
        max-height: calc(100vh - 525px);
    }
  }