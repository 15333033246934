.toggleEditBtn {
    position: absolute;
    top: 8px;
    right: 60px;
    padding-top:6px;
    padding-bottom: 3px;
    padding-left:6px;
    padding-right: 6px;
    border-radius: 30px;
}

.toggleEditBtnTrue {
    position: absolute;
    top: 8px;
    right: 60px;
    padding-top:6px;
    padding-bottom: 3px;
    padding-left:6px;
    padding-right: 6px;
    border-radius: 30px;
    background-color: #EFF2F7;
}

.toggleEditBtn:hover{
    background-color: #EFF2F7;
    cursor: pointer;
}

.dx-icon-showpanel{
    size: 24px;
    font-size: 24px
}

.dx-icon-hidepanel{
    size: 24px;
    font-size: 24px
}

.dx-icon-upload:hover{
    cursor: pointer;
}

.uploadForm{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;

    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

.uploadBtn{
    position:fixed;
    padding: 12px 20px;
    color: green;
    display: none;
    
}

.customUploadFile {
    border: 1px solid #ccc;
    border-radius: 5px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    position: absolute;
    bottom: 25px;
    right: 25px;
}

.customUploadBtnRemove {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.customUploadBtnBrowse {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 5px;
}

.uploadFloorplanForm {
    margin-top: 15px;
}

.uploadFloorplan-DeleteBtn{
    border: 1px solid #ccc;
    border-radius: 5px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    position: absolute;
    bottom: 25px;
    left: 25px;
}

.uploadFloorplan-DeleteBtn-hide{
    visibility: hidden;
}

.testFloorplan{
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1000;
}

.leaflet-draw-section .leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top{
    visibility:var(--thumb-width, visible);
}

.leaflet-draw.leaflet-control{
    display: var(--display, block);
    flex-flow: column-reverse;
    margin-top: var(--margin, 25px);
}

.leaflet-draw-actions li:first-child {
    visibility: var(--save-visible, visible);
    width: var(--save-width, auto)
}

.leaflet-draw-actions .leaflet-tooltip {
    visibility: var(--tooltip-visible, visible);
}

.leaflet-draw-tooltip .leaflet-draw-tooltip-single{
    visibility: hidden;
}

.leaflet-draw-tooltip-single {
    visibility: var(--tooltip-visible, visible) !important;
}

.uploadFloorplan-noteContainer {
    display: flex;
    flex-flow: row;
    padding-top: 10px;
}

.uploadFloorplan-noteText {
    margin-top: 14px;
}

.headerHeadingPage {
    /* padding-top: 10px !important; */
    padding-left: 5px;
    background: #fff !important;
}
.config-space-custom{
    top: 60px;
    margin-right: 42px;
}
.download_btn{
    display: flex;
    align-items: center;
    justify-items: center;
}
.download_btn img{
    width: 22px;
    height: 22px;
}
.download_btn:hover{
    background: rgba(0,0,0,0.08);
}