/* .btnCol {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px !important;
} */
.btnEdit{
    margin-left: 10px;
    cursor: pointer;
}
.device-columnCheckbox{
    margin-bottom: 10px;
}
.device-columnCheckbox:hover{
    cursor: pointer;
}
.columnChooserBtn{
    position: absolute;
    /* top: 8px;
    right: 265px; */
    padding-top:6px;
    padding-bottom: 3px;
    padding-left:6px;
    padding-right: 6px;
    border-radius: 30px;
    top: 54px;
    right: 360px;
}
.columnChooserBtn.col_btn{
    right: 372px;
    height: 36px;
}
.customColBtn{
    cursor: pointer !important;
}
.movePopupSaveBtn{
    background-color: #D71109;
    /* box-shadow: none; */
    color: #fff;
    text-transform: uppercase;
    font-weight: 450;
    letter-spacing: 0.04em;
    border: none;
    height: 36px;
    padding: 6px 8px;
}
.movePopupSaveBtnNoData{
    /* background-color: transparent; */
    /* box-shadow: none; */
    background-color: #D71109;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.04em;
    margin-top: 110%;
}
.columnChooserBtn:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,0.08);
}
.movePopupEditDevice{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 40px;
}
.upload3_devices{
    right: 328px;
}
.device-columnChooserContainer {
    margin-left: 10px;
    margin-top: 10px;
}