.upload-container{
    height: 100vh;
    width: 100%;
    background-color: white;
}

.upload-browseFilesText{
    padding: 20px;

}

.upload-yesNoContainer{
    padding: 20px;
}

.upload-yesNoBtnContainer{
    display: flex;
    flex-flow: row;
}

.upload-yesNoBtn{
    padding: 10px;
    background-color: #d71109;
    width: 200px;
    color: white;
    border-radius: 5px;
    text-align: center;
    margin-right: 20px;
    margin-top: 20px;
}

.upload-floorplanForm{

}

.upload-leaflet{
    height: 400px;
    width: 100%;
}

.classVisible{
    display: block;
}

.classInvisible{
    display: none;
}

.upload-input{
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    flex-flow:row;
    color: rgba(117,117,117,.87);
    font-size: 14px;
}

.upload-inputLabel {
    margin-top:12px;
    margin-bottom:10px;
    width: 150px;
}

.upload-form{
    background-color: #F5F5F5;
    border: 0px;
    border-radius: 3px;
    width: 300px;
    padding: 5px;
    padding-left: 10px;
}


.upload-form:focus{
    outline: none !important;
    padding: 5px;
    padding-left: 10px;
    /* border-width: 5px;
    border-color: black;
    border-bottom: 1px; */
    border-bottom:1px solid #D71109;
}

.upload-setBtn{
    cursor: pointer;
    padding: 10px;
    background-color: #D71109;
    width: 450px;
    color: white;
    border-radius: 5px;
    text-align: center;
    margin-left: 20px;
    margin-top: 20px;
}

.upload-setBtn:hover{
    cursor: pointer;
    padding: 10px;
    background-color: #333;
    width: 450px;
    color: white;
    border-radius: 5px;
    text-align: center;
    margin-left: 20px;
    margin-top: 20px;
}

.upload-setBtn2{
    cursor: pointer;
    padding: 10px;
    background-color: #D71109;
    width: 215px;
    color: white;
    border-radius: 5px;
    text-align: center;
    margin-left: 20px;
    margin-top: 20px;
}
.upload-setBtn2:hover{
    cursor: pointer;
    padding: 10px;
    background-color: #333;
    width: 215px;
    color: white;
    border-radius: 5px;
    text-align: center;
    margin-left: 20px;
    margin-top: 20px;
}

.upload-title{
    font-size: 30px;
    padding-top: 20px;
    margin-bottom: 20px;
    letter-spacing: -.5px;
    font-weight: 300;
    margin-left: 20px;
}

.upload-description{
    color: rgba(117,117,117,.87);
    margin-left: 20px;
    margin-top: 20px;
}