.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}

.profile-formLayout{
  margin: 20px 20px 20px 20px;
  max-width: 500px;
}

.profile-wrapper{
  position: absolute;
  width: 100%;
  height: calc(100vh - (51px));
  background-color: #fff;
}

.profile-save{
    margin: 20px 00px 0px 00px;
    background-color: #D71109;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    width: 10%;
    margin-left: 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    padding: 10px 20px;
    display: inline-block;
    width: auto
}
.profile-save:hover{
  margin: 20px 00px 0px 00px;
  background-color: #000000;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  width: 10%;
  margin-left: 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  padding: 10px 20px;
  display: inline-block;
  width: auto
}
