.entityGroup-add-rule{
    position: absolute;
    top: 10px;
    font-size:24px;
    z-index: 999;
    width: 100%;
}
.div-inner{
    position: relative;
}
.div-inner-name{
    float:left;
    padding-left:25px;
    font-size: large;
}
i.dx-icon-add{
    font-size: 24px;
    float: right;
    position: absolute;
    right: 284px;
}
i.entity-group-custom.dx-icon-close{
    font-size: 24px;
    float: right;
    position: absolute;
    right: 0 !important;
    cursor: pointer;
}
#tenant-rule-grid .dx-item.dx-toolbar-item.dx-toolbar-button {
    padding-right: 15px;
}
.addRule,.delRule{
    color: #D71109;
    font-weight: 500;
    position: absolute;
    bottom: 20px;
    right: 20px;
    line-height: 23px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .04em;
}
.addRule{
    margin-right: 80px;
}

.addRule:hover,.delRule:hover{
    cursor: pointer;
}
