.assetForm{
    height: calc(100vh - (51px + 50px + 48px));
    overflow-y: scroll;
    width: 100%;
    background-color: white;
}

.assetTab-topForm{
    padding: 20px;
    width:500px;
    padding-bottom: 0;
}

.assetTab-bottomForm{
    padding: 20px;
    padding-top: 0;
    width:500px
}

.assetTabh2{
    font-size: large !important;
    font-weight: 400 !important;
}

.ActionsPage{
    display: flex;
    flex-flow: row;
    margin-left: 25px;
}

.saveAttributesBtn{
    position: absolute;
    right: 30px;
    bottom: 30px;
    padding: 10px 20px;
    background-color: #e9e9e9;
    border-radius: 5px;
}

.saveAttributesBtn:hover{
    cursor: pointer;
    background-color: #d3d3d3;
}

.topBarAssetTab{
    height: 50px;
    background-color: white;
}

.title{
    font-size: large;
    padding-top: 15px;
    padding-left: 25px;
}

.backBtn{
    width: 40px;
    height: 40px;
    padding-left: 8px;
    padding-top: 0px;
    position: absolute;
    top: 10px;
    right: 0;
}

.assetTab-checkbox{
    padding: 10px;
}

.assetTab-checkboxText{
    padding-top: 12px;
}

.assetTab-checkboxContainer{
    display: flex;
    flex-flow: row;
    padding-left: 25px;
    padding-top: 15px;
}

.assetTab-saveDiscardContainer{
    display: flex;
    flex-flow: row;
    padding-left: 45px;
    padding-top: 20px;
}

.assetTab-saveDiscardTitle{
    font-weight: 500;
}

.assetTab-saveBtn{
    color: #C4260C;
    font-weight: 500;
    padding: 10px;
    margin-right: 5px;
    border-radius: 3px;
}

.assetTab-saveBtn:hover{
    background-color: #e3a6a544;
    cursor: pointer;
}