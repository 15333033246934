.assetContainer{
    /* height: calc(100vh - (51px + 50px + 48px));  */
    height: auto;
    background-color: white;
    overflow: hidden;
}

.assetMechanicalContainer{
    height: calc(100vh - (51px + 50px + 48px)); 
    /* height: auto; */
    background-color: white;
    overflow: hidden;
}

.dataGridContainerScroll {
    height: 500px;
}

.assetTop{
    height: calc(100vh - 96px);
    overflow-y: auto;
    overflow-x: hidden;
}

.asset-clearBtn{
    padding: 10px 10px;
    cursor: pointer;
    color: #d71109;
}

.asset-clearBtn:hover{
    font-weight: 500;
}

.dateBtn {
    display: flex;
    margin-right: 20px;
    flex-flow: row;
    padding-bottom: 25px;
}

.utilizationdateBtnDropdown {
    /* display: flex; */
    margin-right: 20px;
    flex-flow: row;
    padding-bottom: 0px;
}

.datBtnText{
    padding-left: 10px;
    width: 1000px
}

.btnContainer{
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 0px;
    padding-top: 25px;
    align-items: start;
}

.ptagThreshold{
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 0px;
    padding-top: 0px;
}
input[type=textDevX2] {
    /* width: 100%; */
    max-width: 800px;
    width: 150px;
    height: 40px;
    /* padding: 12px 20px; */
    /* margin: 8px 0; */
    padding-left: 20px;
    margin-right: 10px;
    display: inline;
    border: 1px solid darkgray;
    border-radius: 4px;
    box-sizing: border-box;
}

.saveBtn{
    position: absolute;
    bottom: 25px;
    right: 25px;
}

.bottomInfo{
    display: flex;
    flex-flow: row;
}

.checkbox{
    margin-left: 20px;
}

.customBtn{
    padding:8px 15px;
    max-width: 400px;
    width: 100%;
    min-height: 40px;
    border-width: 1px;
    border: 1px;
    border-radius: 5px;
    border-style: solid;
    display: flex;
    justify-content: space-between;
    color: grey;
}
.customBtn i{
    line-height: 1.4;
    margin-left: 10px;
}

.customBtnContainer{
    width: calc(100%);
}

.customBtn:hover{
    cursor: pointer;
    border-color: black;
}

.customBtnAfter{
    padding:8px;
    padding-left: 10px;
    padding-right: 15px;
    width: 400px;
    height: 40px;
    border-width: 1px;
    border: 1px;
    border-radius: 5px;
    border-style: solid;
    display: flex;
    justify-content: space-between;
}

.dx-texteditor-input{
    font-size: 14px;
    padding-left: 0;
}

.dx-placeholder{
    font-size: 14px;
}

.dx-item-content .dx-list-item-content {
    font-size: 14px;
}

.dx-list-item-content{
    font-size: 14px;
}

.forumText{
    padding-top: 10px;
    text-align: right;
    padding-right: 10px;
}

.backBtn:hover{
    background-color: #ebebeb;
    border-radius: 40px;
    cursor: pointer;
    font-weight: bold;
    padding: 7px 0px 0px 7px;
    margin-top: -7px;
    width: 40px;
    height: 40px;
    top: 10px;
}

.backBtnContainer{
    bottom: 0;
    display: absolute;
}

.spaceBetween{
    display: flex;
    flex-flow: column;
    justify-content:flex-end;
}

.bottomPadding{
    height: 20px;
}

.showFloorplan{
    visibility: visible;
    height: calc(100vh - 303px);
    /* margin-top: 20px; */
}

.showFloorplanMechanical{
    visibility: visible;
    height: calc(75vh - 303px);
    /* margin-top: 20px; */
}

.hideFloorplan{
    visibility: hidden;
    height: 0px;
    font-size: 25px;
    font-weight: bold;
}

.dx-icon-close{
    font-size: 24px;
}

.utilizationTextCheckbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
    border-bottom: 1px solid #cccccc;
}

.utilizationCheckbox {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-top: 20px;
}

.noDataHeatMap {
    z-index: 1111;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.noDataHeatMap > p{
    color: #ffffff;
    font-size: 24px;
}
.gradientScale {
    width: 200px;
    height: 20px;
    float: right;
    background: linear-gradient(to right,
        #fff,
        #469f97,
        #79f215,
        #e8d213,
        #ff0400
    );
}

.gradientScaleParent {
    width: 204px;
    height: 25px;
    border-left: 2px solid #000000;
    border-right: 2px solid #000000;
    float: right;
}
.gradientScaleWithNumber {
    width: 204px;
    height: 36px;
    float: right;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}
.leftgradiantval {
    position: absolute;
    bottom: -22px;
    left: -2px;
}

.rightgradiantval {
    position: absolute;
    bottom: -22px;
    right: -5px;
}

.motionChart {
    height: 200px;
    margin-top: 50px;
}

.activityMinutes {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
}
.custom_asset_btn{
    padding: 5px 12px;
    border: 2px solid #B1B1B1;
    border-radius: 3px;
    background: #fff;
    color: #000;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-right: 18px;
}
.custom_asset_btn:hover{
    border: 2px solid #000;
    font-weight: normal;
}
/* datagrid row selection css */
tr.dx-row.dx-data-row.dx-row-lines.dx-selection {
    background-color: #E6E6E6 !important;
}
.pb20{
    padding-bottom: 20px;
}
.highlighted-row {
    background-color: yellow; /* Change to your desired highlight color */
}
@keyframes blink {
    0%, 50%, 100% {
      background-color: transparent;
    }
    25%, 75% {
      background-color: yellow;
    }
  }
  
  .blink-highlight {
    animation: blink 1s step-start 10;
  }
  .dx-datagrid {
    overflow-x: auto;
  }
  .red-btn{
    /* margin: 20px 00px 0px 00px; */
    background-color: #D71109;
    color: #fff;
    /* padding: 10px; */
    text-align: center;
    border-radius: 5px;
    width: 10%;
    margin-left: 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    padding: 10px 20px;
    display: inline-block;
    width: auto;
}
.red-btn:hover{
    /* margin: 20px 00px 0px 00px; */
    background-color: #333;
    color: #fff;
    /* padding: 10px; */
    text-align: center;
    border-radius: 5px;
    width: 10%;
    margin-left: 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    padding: 10px 20px;
    display: inline-block;
    width: auto;
}
.check-auto-update{
    cursor: pointer;
}
input.csv-upload:hover {
    background-color: #333 !important;
}
label.csv-upload:hover {
    background-color: #333 !important;
}