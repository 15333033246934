.utilizationContainer{
    height: auto;
    background-color: white;
    overflow: hidden;
}
.dateBtnUtilization{
    display: flex;
    margin-right: 20px;
    flex-flow: row;
    padding-bottom: 0px;
}
.leaflet-container img.leaflet-image-layer{
    max-width: 15000px !important;
}