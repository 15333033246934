.save-gateway-btn {
	margin-right: 10%;
	background-color: transparent;
	box-shadow: none;
	color: #D71109;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: .04em;
}

.cancelGatewayBtn {
	background-color: transparent;
	box-shadow: none;
	color: #D71109;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: .04em;
}

.movePopup {
    position: absolute;
    bottom: 10px;
    right: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.btnMove{
	cursor: pointer;
}

#gatewayDetail{
	margin-top: 25px;
    margin-left: 10px;
    width:99%;
}