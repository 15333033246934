.typesbtn {
    position:absolute;
    top: 0;
    right: 325px;
    z-index: 1;
    background-color: #f2f2f2;
    padding: 10px;
    margin-top: 5px;
    border-radius: 3px;
}

.typesbtn:hover {
    background-color: #e6e6e6;
    cursor: pointer;
}

.saveBtn2{
    position: absolute;
    z-index: 99;
    bottom: 25px;
    right: 25px;
    padding: 15px;
    padding-left: 85px;
    padding-right: 85px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #c2c2c2;
}

.saveBtn3{
    position: absolute;
    bottom: 15px;
    right: 25px;
}

.dateBtn2{
    position: absolute;
    top: 5px;
    left: 17px;
    z-index: 99;
    display: flex;
    color: #757575;
}

.inventoryBtn {
    position: absolute;
    bottom:15px;
    left: 22px;
}

.inventoryBtnShow{
    position: absolute;
    font-size: 13px;
    top:101px;
    left: 22px;
    background-color: white;
    padding-left: 20px;
    padding-top: 11px;
    padding-bottom: 10px;
    padding-right: 151px;
    border-bottom: #EFF2F7;
    border-right: #EFF2F7;
    border-top: white;
    border-left: white;
    border-width: 1px;
    border-style: solid;
    z-index: 99;
    color: #3D91FF;
    font-weight: bold;
}

.inventoryBtnShow:hover{
    background-color: #EFF2F7;
    cursor: pointer;
}

.assets-columnCheckbox{
    margin-bottom: 10px;
}

.assets-columnCheckbox:hover{
    cursor: pointer;
}

.assets-clearDate{
    padding: 10px 10px;
    color: #d71109;
    white-space: nowrap;
}

.assets-clearDate:hover {
    cursor: pointer;
    font-weight: 420;
}

.testEdit{
    position: absolute;
    top: 8px;
    right: 500px;
}

.uploadBtnAssets{
    position: absolute;
    top: 8px;
    right: 352px;
    padding: 6px;
    border-radius: 30px;
    line-height: normal;
}
.uploadBtnAssetsReport{
    position: absolute;
    top: 8px;
    right: 352px;
    padding: 6px;
    border-radius: 30px;
    line-height: normal;
}

.uploadBtnAssets2{
    position: absolute;
    top: 8px;
    right: 312px;
    padding: 6px;
    border-radius: 30px;
    line-height: normal;
}

.uploadBtnAssets2:hover{
    cursor: pointer;
    background-color: rgba(0,0,0,0.08);
}

.uploadBtnAssets:hover{
    background-color: rgba(0,0,0,0.08);
    cursor: pointer;
}

.uploadSave{
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.app .content-block {
    margin: 0px !important;
    height: 0px !important;
    visibility: hidden !important;
}
.content.headerHeadingPage {
    background: #fff !important;
    position: absolute;
    top: 50px;
    border: 3px solid green;
}
.pageFullWidthEvents{
    padding: 10px;
    top:5px
}
.date_position{
    top: 51px;
    width: calc(100% - 500px);
}
.upload1{
    top: 54px;
    right: 404px;
}
.upload2{
    top: 54px;
    right: 365px;
}
.upload3{
    top: 54px;
    right: 445px;
}
.upload4{
    top: 40px;
    right: 492px;
}
.upload44{
    top: 54px;
    right: 486px;
}
.clrConfig{
    top: 42px;
    right: 328px;
}
.clrGateway{
    top: 42px;
    right: 284px;
}
/* #full-page-content-assets{
     overflow-y: scroll; 
}*/
.custom-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.uploadBtnAssets.upload3:hover{
    background: none;
}
.add-attrib-button span.dx-button-text{
    color: #FFFFFF;
}
.editAttribute-setNote{
    color: #D71109;
    font-weight: 500;
    position: absolute;
    bottom: 50px;
    line-height: 23px;
    font-weight: 500;
    letter-spacing: .04em;
}
.column_two{
    display: grid;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
    gap: 20px;
    padding: 0 20px 20px;
}
@media all and (max-width:1024px){
    .column_two{
        display: grid;
        grid-template-columns: 100%;
    }
    .btnContainer{
        flex-direction: column;
        align-items: self-start;
    }
}
.uploadBtnAssetsReport.upload3:hover{
    cursor: pointer;
    background-color: rgba(0,0,0,0.08);
    /* height: 42px;
    padding: 6px 6px 4px 4px; */
}
.uploadBtnAssetsReport.upload44:hover {
    background-color: rgba(0, 0, 0, .08);
    cursor: pointer;
}
.dx-widget.dx-button.dx-button-mode-outlined.dx-button-normal.dx-button-has-text:hover {
    background: #000;
    color: #fff;
}
.dg-text-above{
    padding-left: 20px;
    padding-bottom: 5px;
    font-size: 12px;
}
.dg-text{
    /* padding-left: 20px; */
    /* padding-bottom: 5px; */
    font-size: 12px;
}
@media all and (min-width:1441px){
    .upload1{
        right: 412px;
    }
    .upload2{
        right: 370px;
    }
    .upload3{
        right: 453px;
        height: 36px;
    }
    .upload44{
        right: 496px;
    }
    .upload44 button img{
        width: 24px;
        height: 24px;
    }
}
.clear-filters-button {
    background-color: transparent;
    border: 5px solid #FE2500;
    border-radius: 20px;
    color: #FE2500;
    padding: 10px 20px;
    font-size: 16px;
    outline: none;
    cursor: pointer;
  }
  .clear-filters-button-disabled {
    border-radius: 20px;
    background-color: transparent;
    border: 5px solid grey;
    color: grey;
    padding: 10px 20px;
    font-size: 16px;
    outline: none;
  }
  .custom-tooltip .dx-tooltip-content {
    font-size: 16px;   /* Adjust the font size */
    padding: 10px;     /* Add padding for better spacing */
    max-width: 300px;  /* Set a maximum width for the tooltip */
  }
  .pageFullWidthEvents .dx-treelist-container,
.pageFullWidthEvents .dxc-chart{
    /* max-height:200px; */
    max-height: var(--height);
    overflow: hidden;
}
.download_btn{
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: 50%;
    padding: 6px;
}
.download_btn img{
    width: 22px;
    height: 22px;
}
.download_btn:hover{
    background: rgba(0,0,0,0.08);
}
.add-atrib-btn:hover{
    background-color: #333 !important;
}
.upload55{
    top: 56px;
    right: 528px;
}
.uploadBtnAssetsReport.upload55:hover {
    background-color: rgba(0, 0, 0, .08);
    cursor: pointer;
}
.uploadBtnAssetsReport.upload55.disable-analyze {
    /* background-color: rgba(0, 0, 0, .08); */
    cursor: not-allowed;
    opacity: 0.6;
}
